import { fetchWithTimeout } from '@/common/shared'
import { format } from 'date-fns'
import { keycloakBaseUrl, epicMrnSystem, keycloakEhrLaunchIdp } from '@/settings'
import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'

const callEhrTokenPayload = async (kcIdpHint) => {
  const exchangeUrl = `${keycloakBaseUrl}/broker/${kcIdpHint}/token`
  const params = {
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + window.access_token },
  }
  const externalPayloadResponse = await fetchWithTimeout(exchangeUrl, params)
  if (externalPayloadResponse.status === 200) {
    const externalPayloadResult = await externalPayloadResponse.json()
    return externalPayloadResult
  }
  console.log('Not an EHR launch, continue with standalone mode.')
  return
}

const getFhirPatient = async (externalPayload, aud) => {
  if (!externalPayload?.patient || !aud) return ''
  const patientRequestUrl = `${aud}/Patient/${externalPayload.patient}`
  const params = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + externalPayload.access_token },
  }
  const patientResourceResponse = await fetchWithTimeout(patientRequestUrl, params)
  if (patientResourceResponse.status !== 200) { return '' }
  const patientResource = await patientResourceResponse.json()
  return patientResource
}

// getMrnSystem may return '' or `undefined`, and that is OK, as it will be handled in getPatientMrn.
const getMrnSystem = (organization) => {
  try {
    return JSON.parse(epicMrnSystem)[organization]
  } catch {
    console.log('VUE_APP_EPIC_MRN_SYSTEM is not a valid JSON string')
    return ''
  }
}

const getPatientMrn = (patientResource, mrnSystem) => {
  if (!mrnSystem || !patientResource) { return '' }
  const identifier = patientResource.identifier.find((id) => {
    return id.system === mrnSystem
  })
  return identifier?.value || ''
}

// getAudFromIdp may return '' or `undefined`, and that is OK, as it will be handled in getFhirPatient.
const getAudFromIdp = (idp) => {
  if (!idp) { return '' }
  try {
    const keycloakEhrLaunchIdpObject = JSON.parse(keycloakEhrLaunchIdp)
    return Object.keys(keycloakEhrLaunchIdpObject).find(key => keycloakEhrLaunchIdpObject[key] === idp)
  } catch {
    console.log('VUE_APP_KEYCLOAK_EHR_LAUNCH_IDP is not a valid JSON string')
    return ''
  }
}

const createEpicPatient = (externalPayload, mrn) => ({
  birthdate: format(new Date(externalPayload.dob.replaceAll('%2F', '-')), 'yyyy-MM-dd'),
  family: externalPayload.lname,
  gender: externalPayload.gender,
  given: externalPayload.fname,
  id: mrn,
  name: `${externalPayload.lname}, ${externalPayload.fname}`,
  isChartLaunchedPatient: true,
})

export const setEhrPatientSession = async (store) => {
  const aud = getAudFromIdp(window.idp)
  const externalPayload = await callEhrTokenPayload(window.idp)
  const patientResource = await getFhirPatient(externalPayload, aud)
  const mrnSystem = getMrnSystem(window.idp)
  const mrn = getPatientMrn(patientResource, mrnSystem)
  if (externalPayload?.patient && mrn) {
    const patient = createEpicPatient(externalPayload, mrn)
    store.dispatch('setSessionPatient', { patient, publish: true })
    refreshToolPositions()
  }
}
